$(document).ready(function() {

    /*
        GLOBAL
     */
    $(".fancybox").fancybox({
        backFocus : false
    });

    if ($(window).width() > 767) {
        $('#main-menu .navbar-nav .nav-item.has-submenu').on('hover', function () {
            $(this).find('> .submenu').toggle();
        });
    }

    $('#main-menu .navbar-nav .nav-item.has-submenu .chevron').on('click', function() {
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
        } else {
            $(this).parent().addClass('active');
        }

        $(this).parent().find('> .submenu').slideToggle();
    });

    $('#launch-popup-coming-soon, .launch-popup-coming-soon').fancybox();

    document.addEventListener( 'wpcf7mailsent', function( event ) {
        if ( '118' == event.detail.contactFormId ) {
            $('#popup-coming-soon .wpcf7-submit').hide();
        }
    }, false );

    // OFI
    objectFitImages();

    $('header#header .nav-search .is-search-form .is-search-submit').on('click', function() {

        $('header#header .is-search-form .is-search-input').toggle();

        return false;
    });

    $('header#header #search-mobile .is-search-form .is-search-submit').on('click', function() {

        if ($(this).closest('#search-mobile').hasClass('active'))
            $(this).closest('#search-mobile').removeClass('active');
        else
            $(this).closest('#search-mobile').addClass('active');

        $('header#header #search-mobile .is-search-form .is-search-input').toggle();

        return false;
    });


    if (window.location.hash != '' && $(window.location.hash).length > 0 && window.location.hash != '#popup-coming-soon') {
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);

        var header_height = $('header#header').height();

        $('body, html').animate({
            scrollTop: $(window.location.hash).offset().top - header_height
        }, 700);
    }

    if (window.location.hash == '#popup-coming-soon') {
       $('#launch-popup-coming-soon').click();
    }

    $(document).on('change', '#popup-coming-soon .choice-b2c-b2b input[name="choice-type-client"]', function() {
        var customer_type = $(this).val();

        if (customer_type == 'b2c') {
            $('#form-newsletter-b2c').show();
            $('#form-newsletter-b2b').hide();
        } else {
            $('#form-newsletter-b2c').hide();
            $('#form-newsletter-b2b').show();
        }
    });

});
